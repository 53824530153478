<template lang="pug">
	.widget-container.stats-part
		el-row(:gutter="16")
			el-col(:xs="24" :md="12" )
				el-card
					template(slot="header")
						h1 10 Last Registrations
					el-table(id="data-list", v-loading='busy', :data='list', element-loading-text='Loading', border='', fit='', highlight-current-row='')
						el-table-column(align='center', label="Username")
							template(slot-scope='scope')
								span {{ scope.row.username || '-' }}
						el-table-column(align='center', label="First Name")
							template(slot-scope='scope')
								span {{ scope.row.first_name || '-' }}
						el-table-column(align='center', label="Chat ID")
							template(slot-scope='scope')
								span {{ scope.row.chat_id || '-' }}
						el-table-column(align='center', label="Registration" :width="135")
							template(slot-scope='scope')
								span {{ (scope.row.createdAt || '-') | formatDateTime }}
			el-col(:xs="24" :md="12" )
				el-card.mg.b3
					template(slot="header")
						h1 Weekly Messages
							bar-chart(v-if="chart.weeklyMessages.labels.length > 0" v-loading="chartLoading" :data="chart.weeklyMessages.data" :dataLine="chart.weeklyMessages.dataLine" :labels="chart.weeklyMessages.labels" :minLim="0" )
							.mock-loading(v-else="")
		.text-left
			//pre {{  chartDump }}
			//pre {{ chart.weeklyMessages.labels }}
</template>

<style lang="scss">
.widget-container {

	&.stats-part {
		padding-top: 16px;
	}

	@media only screen and (max-width: 767px) {

		&.stats-part {
			margin-top: 0.6rem;
			margin-left: 0.5rem;
			margin-right: 0.5rem;

			.dashboard-box-container {
				width: 100%;
				margin-bottom: 6px;
				margin-top: 6px;

				&.panel-box {
					height: 6rem;
				}
			}
		}
	}
}
</style>

<script>
import {GetByTopic} from "@/api/dashboard";
import barChart from '@/components/bar';
let sort = (list, key) =>  list.forEach((e,i) => list[i].msg = e.msg.sort( (a, b) => b[key] - a[key]));
const sortObject = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {})
export default {
	name: 'statistics',
	components: { barChart },
	data() {
		return {
			busy: false,
			chartLoading: false,
			chart: {
				weeklyMessages: {
					data: [],
					dataLine: [],
					labels: []
				}
			},
			list: [],
			chartDump: []
		}
	},
	created() {
		GetByTopic('10').then(res => this.list = res)
		GetByTopic('weekly_chart').then(res => {
			const hash = {};
			res.msgResult.forEach(e => !!hash[e._id] ? hash[e._id].msg = e.count : hash[e._id] = { msg: e.count || 0 });
			res.leadResult.forEach(e => !!hash[e._id] ? hash[e._id].lead = e.count : hash[e._id] = { lead: e.count || 0 });
			this.chartDump = hash;
			const newHash = sortObject(hash)
			Object.entries(newHash).forEach(e => {
				this.chart.weeklyMessages.labels.push(e[0]);
				this.chart.weeklyMessages.data.push(e[1].msg ? e[1].msg : 0);
				this.chart.weeklyMessages.dataLine.push(e[1].lead ? e[1].lead : 0);
			});

		})
	}
}
</script>
