<template lang="pug">
	#sections
		#section-1
			panels
		#section-2
			statistics

</template>

<style lang="scss" scoped>
.mock-loading {
	height: 400px;

}

</style>

<script>
import panels from './modules/panels';
import statistics from './modules/statistics';
export default {
	name: 'dashboard',
	components: { panels, statistics }
}
</script>
