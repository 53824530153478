<style lang="scss">
.chart-wrapper {
	width: 100%;
	height: 700px;
}
.echarts {
	width: 100%;
	height: 100%;
}
</style>

<script>
import { Bar } from 'vue-chartjs';
export default {
	name: 'bar-chart',
	extends: Bar,
	props: {
		threshold: Number,
		minLim: Number,
		data: Array,
		dataLine: Array,
		labels: Array,
	},
	data() {
		return {
			chartdata: {
				labels: this.labels,
				datasets: [
					{
						label: 'Messages sent',
						backgroundColor:'rgb(255,30,0, 0.7)',
						data: this.data,
						yAxisID: 'A',
						order: 2

					}, {
						label: 'Leads Registered',
						data: this.dataLine,
						backgroundColor:'rgba(34,93,133,0.3)',
						type: 'line',
						yAxisID: 'B',
						order: 1
					}
				]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					yAxes: [{
						id: 'A',
						type: 'linear',
						position: 'left',
					}, {
						id: 'B',
						type: 'linear',
						position: 'right',
						ticks: {
							max: this.threshold,
							min: this.minLim
						}
					}]
				}
			}
		}
	},
	mounted () {
		console.log(this.chartdata, this.options)
		this.renderChart(this.chartdata, this.options)
	}
}
</script>
