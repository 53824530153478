<template lang="pug">
	.widget-container.panels(style="padding-bottom:0")
		el-row(:gutter="16")
			el-col.pan-col(:xs="12" :sm="6"  )
				el-card.dashboard-box-container.panel-box
					.d-flex.j-between
						font-awesome-icon.icon.alt.big-icon(size="lg", :icon="['fa', 'user-secret']")
						.count-text
							label Total Leads
							count-to.card-panel-num(:start-val='0', :end-val='panel.total_leads', :duration='2600')
			el-col.pan-col(:xs="12" :sm="6" )
				el-card.dashboard-box-container.panel-box
					.d-flex.j-between
						font-awesome-icon.icon.alt.big-icon(size="lg", :icon="['fa', 'users']")
						.count-text
							label Total Groups
							count-to.card-panel-num(:start-val='0', :end-val='panel.total_groups', :duration='2600')
			el-col.pan-col(:xs="12" :sm="6")
				el-card.dashboard-box-container.panel-box
					.d-flex.j-between
						font-awesome-icon.icon.alt.big-icon(size="lg", :icon="['fa', 'plane']")
						.count-text
							label Messages Sent
							count-to.card-panel-num(:start-val='0', :end-val='panel.messages_sent', :duration='2600')
			el-col.pan-col(:xs="12" :sm="6"  )
				el-card.dashboard-box-container.panel-box
					.d-flex.j-between
						font-awesome-icon.icon.alt.big-icon(size="lg", :icon="['fa', 'plus']")
						.count-text
							label Today Registered
							count-to.card-panel-num(:start-val='0', :end-val='panel.today_regs', :duration='2600')
</template>

<style lang="scss">
.widget-container {

	&.panels {
		padding-top: 16px;
	}

	@media only screen and (max-width: 767px) {

		&.panels {
			padding-bottom: 16px;
			margin-left: 0.5rem;
			margin-right: 0.5rem;
			.dashboard-box-container {
				width: 100%;
				margin-bottom: 6px;
				margin-top: 6px;
        &.panel-box {
          height: 6rem;
        }
			}
		}
	}
	.dashboard-box-container:hover {
		cursor: pointer;
		background-color: #0d2e47;
		color: #fafafa;

		.big-icon {
			color: white;
		}
	}
	.text-bold {
		font-weight: 600;
	}
	.big-icon {
		width: 40px;
		height: 40px;
		color: #0d2e47;
	}
	.count-text {
		text-align: right;

		label {
			display: block;
		}
	}
}
</style>

<script>
import countTo from "vue-count-to";
import {GetByTopic} from "@/api/dashboard";
export default {
	name: 'panels',
	components: { countTo },
	data() {
		return {
			panel: {
				total_leads: 0,
				total_groups: 0,
				messages_sent: 0,
				today_regs: 0,
			}
		}
	},
	created() {
		GetByTopic("total_leads").then(res => this.panel.total_leads = res);
		GetByTopic("total_groups").then(res => this.panel.total_groups = res);
		GetByTopic("messages_sent").then(res => this.panel.messages_sent = res);
		GetByTopic("today_regs").then(res => this.panel.today_regs = res);
	}
}
</script>
